
    <template>
      <section class="content element-doc">
        <h2>Collapse 折叠面板</h2>
<p>通过折叠面板收纳内容区域</p>
<h3>基础用法</h3>
<p>可同时展开多个面板，面板之间不影响</p>
<demo-block>
        
        <template #source><element-demo0 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-collapse v-model=&quot;activeNames&quot; @change=&quot;handleChange&quot;&gt;
  &lt;el-collapse-item title=&quot;一致性 Consistency&quot; name=&quot;1&quot;&gt;
    &lt;div&gt;
      与现实生活一致：与现实生活的流程、逻辑保持一致，遵循用户习惯的语言和概念；
    &lt;/div&gt;
    &lt;div&gt;
      在界面中一致：所有的元素和结构需保持一致，比如：设计样式、图标和文本、元素的位置等。
    &lt;/div&gt;
  &lt;/el-collapse-item&gt;
  &lt;el-collapse-item title=&quot;反馈 Feedback&quot; name=&quot;2&quot;&gt;
    &lt;div&gt;控制反馈：通过界面样式和交互动效让用户可以清晰的感知自己的操作；&lt;/div&gt;
    &lt;div&gt;页面反馈：操作后，通过页面元素的变化清晰地展现当前状态。&lt;/div&gt;
  &lt;/el-collapse-item&gt;
  &lt;el-collapse-item title=&quot;效率 Efficiency&quot; name=&quot;3&quot;&gt;
    &lt;div&gt;简化流程：设计简洁直观的操作流程；&lt;/div&gt;
    &lt;div&gt;清晰明确：语言表达清晰且表意明确，让用户快速理解进而作出决策；&lt;/div&gt;
    &lt;div&gt;
      帮助用户识别：界面简单直白，让用户快速识别而非回忆，减少用户记忆负担。
    &lt;/div&gt;
  &lt;/el-collapse-item&gt;
  &lt;el-collapse-item title=&quot;可控 Controllability&quot; name=&quot;4&quot;&gt;
    &lt;div&gt;
      用户决策：根据场景可给予用户操作建议或安全提示，但不能代替用户进行决策；
    &lt;/div&gt;
    &lt;div&gt;
      结果可控：用户可以自由的进行操作，包括撤销、回退和终止当前操作等。
    &lt;/div&gt;
  &lt;/el-collapse-item&gt;
&lt;/el-collapse&gt;
&lt;script&gt;
  import { ref } from 'vue'

  export default {
    setup() {
      const activeNames = ref(['1'])
      function handleChange(val) {
        console.log(val)
      }

      return {
        activeNames,
        handleChange
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>手风琴效果</h3>
<p>每次只能展开一个面板</p>
<demo-block>
        <div><p>通过 <code>accordion</code> 属性来设置是否以手风琴模式显示。</p>
</div>
        <template #source><element-demo1 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-collapse v-model=&quot;activeName&quot; accordion&gt;
  &lt;el-collapse-item title=&quot;一致性 Consistency&quot; name=&quot;1&quot;&gt;
    &lt;div&gt;
      与现实生活一致：与现实生活的流程、逻辑保持一致，遵循用户习惯的语言和概念；
    &lt;/div&gt;
    &lt;div&gt;
      在界面中一致：所有的元素和结构需保持一致，比如：设计样式、图标和文本、元素的位置等。
    &lt;/div&gt;
  &lt;/el-collapse-item&gt;
  &lt;el-collapse-item title=&quot;反馈 Feedback&quot; name=&quot;2&quot;&gt;
    &lt;div&gt;控制反馈：通过界面样式和交互动效让用户可以清晰的感知自己的操作；&lt;/div&gt;
    &lt;div&gt;页面反馈：操作后，通过页面元素的变化清晰地展现当前状态。&lt;/div&gt;
  &lt;/el-collapse-item&gt;
  &lt;el-collapse-item title=&quot;效率 Efficiency&quot; name=&quot;3&quot;&gt;
    &lt;div&gt;简化流程：设计简洁直观的操作流程；&lt;/div&gt;
    &lt;div&gt;清晰明确：语言表达清晰且表意明确，让用户快速理解进而作出决策；&lt;/div&gt;
    &lt;div&gt;
      帮助用户识别：界面简单直白，让用户快速识别而非回忆，减少用户记忆负担。
    &lt;/div&gt;
  &lt;/el-collapse-item&gt;
  &lt;el-collapse-item title=&quot;可控 Controllability&quot; name=&quot;4&quot;&gt;
    &lt;div&gt;
      用户决策：根据场景可给予用户操作建议或安全提示，但不能代替用户进行决策；
    &lt;/div&gt;
    &lt;div&gt;
      结果可控：用户可以自由的进行操作，包括撤销、回退和终止当前操作等。
    &lt;/div&gt;
  &lt;/el-collapse-item&gt;
&lt;/el-collapse&gt;
&lt;script&gt;
  import { ref } from 'vue'

  export default {
    setup() {
      const activeName = ref('1')

      return {
        activeName
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>自定义面板标题</h3>
<p>除了可以通过 <code>title</code> 属性以外，还可以通过具名 <code>slot</code> 来实现自定义面板的标题内容，以实现增加图标等效果。</p>
<demo-block>
        
        <template #source><element-demo2 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-collapse accordion&gt;
  &lt;el-collapse-item&gt;
    &lt;template v-slot:title&gt;
      一致性 Consistency&lt;i class=&quot;header-icon el-icon-info&quot;&gt;&lt;/i&gt;
    &lt;/template&gt;
    &lt;div&gt;
      与现实生活一致：与现实生活的流程、逻辑保持一致，遵循用户习惯的语言和概念；
    &lt;/div&gt;
    &lt;div&gt;
      在界面中一致：所有的元素和结构需保持一致，比如：设计样式、图标和文本、元素的位置等。
    &lt;/div&gt;
  &lt;/el-collapse-item&gt;
  &lt;el-collapse-item title=&quot;反馈 Feedback&quot;&gt;
    &lt;div&gt;控制反馈：通过界面样式和交互动效让用户可以清晰的感知自己的操作；&lt;/div&gt;
    &lt;div&gt;页面反馈：操作后，通过页面元素的变化清晰地展现当前状态。&lt;/div&gt;
  &lt;/el-collapse-item&gt;
  &lt;el-collapse-item title=&quot;效率 Efficiency&quot;&gt;
    &lt;div&gt;简化流程：设计简洁直观的操作流程；&lt;/div&gt;
    &lt;div&gt;清晰明确：语言表达清晰且表意明确，让用户快速理解进而作出决策；&lt;/div&gt;
    &lt;div&gt;
      帮助用户识别：界面简单直白，让用户快速识别而非回忆，减少用户记忆负担。
    &lt;/div&gt;
  &lt;/el-collapse-item&gt;
  &lt;el-collapse-item title=&quot;可控 Controllability&quot;&gt;
    &lt;div&gt;
      用户决策：根据场景可给予用户操作建议或安全提示，但不能代替用户进行决策；
    &lt;/div&gt;
    &lt;div&gt;
      结果可控：用户可以自由的进行操作，包括撤销、回退和终止当前操作等。
    &lt;/div&gt;
  &lt;/el-collapse-item&gt;
&lt;/el-collapse&gt;
</code></pre></template></demo-block><h3>Collapse Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>modelValue / v-model</td>
<td>当前激活的面板(如果是手风琴模式，绑定值类型需要为<code>string</code>，否则为<code>array</code>)</td>
<td>string / array</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>accordion</td>
<td>是否手风琴模式</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
</tbody>
</table>
<h3>Collapse Events</h3>
<table>
<thead>
<tr>
<th>事件名称</th>
<th>说明</th>
<th>回调参数</th>
</tr>
</thead>
<tbody>
<tr>
<td>change</td>
<td>当前激活面板改变时触发(如果是手风琴模式，参数 <code>activeNames</code> 类型为<code>string</code>，否则为<code>array</code>)</td>
<td>(activeNames: array / string)</td>
</tr>
</tbody>
</table>
<h3>Collapse Item Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>name</td>
<td>唯一标志符</td>
<td>string/number</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>title</td>
<td>面板标题</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>disabled</td>
<td>是否禁用</td>
<td>boolean</td>
<td>—</td>
<td>—</td>
</tr>
</tbody>
</table>

      </section>
    </template>
    <script>
      import hljs from 'highlight.js'
      import * as Vue from "vue"
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    const { createVNode: _createVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_collapse_item = _resolveComponent("el-collapse-item")
  const _component_el_collapse = _resolveComponent("el-collapse")

  return (_openBlock(), _createBlock(_component_el_collapse, {
    modelValue: _ctx.activeNames,
    "onUpdate:modelValue": $event => (_ctx.activeNames = $event),
    onChange: _ctx.handleChange
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_collapse_item, {
        title: "一致性 Consistency",
        name: "1"
      }, {
        default: _withCtx(() => [
          _createVNode("div", null, " 与现实生活一致：与现实生活的流程、逻辑保持一致，遵循用户习惯的语言和概念； "),
          _createVNode("div", null, " 在界面中一致：所有的元素和结构需保持一致，比如：设计样式、图标和文本、元素的位置等。 ")
        ]),
        _: 1
      }),
      _createVNode(_component_el_collapse_item, {
        title: "反馈 Feedback",
        name: "2"
      }, {
        default: _withCtx(() => [
          _createVNode("div", null, "控制反馈：通过界面样式和交互动效让用户可以清晰的感知自己的操作；"),
          _createVNode("div", null, "页面反馈：操作后，通过页面元素的变化清晰地展现当前状态。")
        ]),
        _: 1
      }),
      _createVNode(_component_el_collapse_item, {
        title: "效率 Efficiency",
        name: "3"
      }, {
        default: _withCtx(() => [
          _createVNode("div", null, "简化流程：设计简洁直观的操作流程；"),
          _createVNode("div", null, "清晰明确：语言表达清晰且表意明确，让用户快速理解进而作出决策；"),
          _createVNode("div", null, " 帮助用户识别：界面简单直白，让用户快速识别而非回忆，减少用户记忆负担。 ")
        ]),
        _: 1
      }),
      _createVNode(_component_el_collapse_item, {
        title: "可控 Controllability",
        name: "4"
      }, {
        default: _withCtx(() => [
          _createVNode("div", null, " 用户决策：根据场景可给予用户操作建议或安全提示，但不能代替用户进行决策； "),
          _createVNode("div", null, " 结果可控：用户可以自由的进行操作，包括撤销、回退和终止当前操作等。 ")
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue", "onUpdate:modelValue", "onChange"]))
}
  
    
        const { ref } = Vue
        

  const democomponentExport = {
    setup() {
      const activeNames = ref(['1'])
      function handleChange(val) {
        console.log(val)
      }

      return {
        activeNames,
        handleChange
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo1": (function() {
    
    const { createVNode: _createVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_collapse_item = _resolveComponent("el-collapse-item")
  const _component_el_collapse = _resolveComponent("el-collapse")

  return (_openBlock(), _createBlock(_component_el_collapse, {
    modelValue: _ctx.activeName,
    "onUpdate:modelValue": $event => (_ctx.activeName = $event),
    accordion: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_collapse_item, {
        title: "一致性 Consistency",
        name: "1"
      }, {
        default: _withCtx(() => [
          _createVNode("div", null, " 与现实生活一致：与现实生活的流程、逻辑保持一致，遵循用户习惯的语言和概念； "),
          _createVNode("div", null, " 在界面中一致：所有的元素和结构需保持一致，比如：设计样式、图标和文本、元素的位置等。 ")
        ]),
        _: 1
      }),
      _createVNode(_component_el_collapse_item, {
        title: "反馈 Feedback",
        name: "2"
      }, {
        default: _withCtx(() => [
          _createVNode("div", null, "控制反馈：通过界面样式和交互动效让用户可以清晰的感知自己的操作；"),
          _createVNode("div", null, "页面反馈：操作后，通过页面元素的变化清晰地展现当前状态。")
        ]),
        _: 1
      }),
      _createVNode(_component_el_collapse_item, {
        title: "效率 Efficiency",
        name: "3"
      }, {
        default: _withCtx(() => [
          _createVNode("div", null, "简化流程：设计简洁直观的操作流程；"),
          _createVNode("div", null, "清晰明确：语言表达清晰且表意明确，让用户快速理解进而作出决策；"),
          _createVNode("div", null, " 帮助用户识别：界面简单直白，让用户快速识别而非回忆，减少用户记忆负担。 ")
        ]),
        _: 1
      }),
      _createVNode(_component_el_collapse_item, {
        title: "可控 Controllability",
        name: "4"
      }, {
        default: _withCtx(() => [
          _createVNode("div", null, " 用户决策：根据场景可给予用户操作建议或安全提示，但不能代替用户进行决策； "),
          _createVNode("div", null, " 结果可控：用户可以自由的进行操作，包括撤销、回退和终止当前操作等。 ")
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue", "onUpdate:modelValue"]))
}
  
    
        const { ref } = Vue
        

  const democomponentExport = {
    setup() {
      const activeName = ref('1')

      return {
        activeName
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo2": (function() {
    
    const { createVNode: _createVNode, createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_collapse_item = _resolveComponent("el-collapse-item")
  const _component_el_collapse = _resolveComponent("el-collapse")

  return (_openBlock(), _createBlock(_component_el_collapse, { accordion: "" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_collapse_item, null, {
        title: _withCtx(() => [
          _createTextVNode(" 一致性 Consistency"),
          _createVNode("i", { class: "header-icon el-icon-info" })
        ]),
        default: _withCtx(() => [
          _createVNode("div", null, " 与现实生活一致：与现实生活的流程、逻辑保持一致，遵循用户习惯的语言和概念； "),
          _createVNode("div", null, " 在界面中一致：所有的元素和结构需保持一致，比如：设计样式、图标和文本、元素的位置等。 ")
        ]),
        _: 1
      }),
      _createVNode(_component_el_collapse_item, { title: "反馈 Feedback" }, {
        default: _withCtx(() => [
          _createVNode("div", null, "控制反馈：通过界面样式和交互动效让用户可以清晰的感知自己的操作；"),
          _createVNode("div", null, "页面反馈：操作后，通过页面元素的变化清晰地展现当前状态。")
        ]),
        _: 1
      }),
      _createVNode(_component_el_collapse_item, { title: "效率 Efficiency" }, {
        default: _withCtx(() => [
          _createVNode("div", null, "简化流程：设计简洁直观的操作流程；"),
          _createVNode("div", null, "清晰明确：语言表达清晰且表意明确，让用户快速理解进而作出决策；"),
          _createVNode("div", null, " 帮助用户识别：界面简单直白，让用户快速识别而非回忆，减少用户记忆负担。 ")
        ]),
        _: 1
      }),
      _createVNode(_component_el_collapse_item, { title: "可控 Controllability" }, {
        default: _withCtx(() => [
          _createVNode("div", null, " 用户决策：根据场景可给予用户操作建议或安全提示，但不能代替用户进行决策； "),
          _createVNode("div", null, " 结果可控：用户可以自由的进行操作，包括撤销、回退和终止当前操作等。 ")
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
  